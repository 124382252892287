<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">景观视线监测数据</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="景观视线名称" class="from-item-block">
                    <el-select
                        v-model="formdata.dataDic.JGSLID"
                        :disabled="!isEdit || $route.query.JCDXID"
                    >
                        <el-option
                            v-for="(item, index) in jgfmList"
                            :key="`${index}`"
                            :value="item.ID"
                            :label="item.MC"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="XZSFFSBH"
                    label="现状是否发生变化"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.XZSFFSBH"
                        :disabled="!isEdit"
                        @change="changeXzsffsbd()"
                    >
                        <el-option
                            v-for="(item, index) in changeStateList"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-if="formdata.dataDic.XZSFFSBH == '1'"
                    label="引起变化的原因"
                    class="from-item-block"
                >
                    <el-select
                        v-model="yqbhdyyList"
                        multiple
                        :disabled="!isEdit"
                        @change="changeReason()"
                    >
                        <el-option
                            v-for="(item, index) in bdlxList"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <!-- 变动影响程度 -->
                <el-form-item
                    v-if="formdata.dataDic.XZSFFSBH == '1'"
                    label="影响评估"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.YXPG"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in bdyxcdList"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="QKSM"
                    label="情况说明"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.QKSM"
                        resize="none"
                        type="textarea"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>

                <el-form-item prop="BZ" label="备注" class="from-item-block">
                    <el-input
                        v-model="formdata.dataDic.BZ"
                        resize="none"
                        type="textarea"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
                <el-form-item label="现场照片" class="from-item-block">
                    <basic-upload
                        ref="uploadFile"
                        :limit="1"
                        :isEdit="isEdit"
                        :itemId="itemId"
                        :metaData.sync="formdata.metaJson"
                        basicType="picture"
                        :disabled="!isEdit"
                        :maxSize="500"
                    ></basic-upload>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "jghjmonitor",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: this.$route.query.ItemID,
                dataDic: {
                    glycbtid: this.heritageId,
                    JGSLID: "",
                    XZSFFSBH: "1",
                    YQBHYY: [],
                    QKSM: "",
                    YXPG: "",
                    BZ: "",
                },
            },
            rules: {},
            bdlxList: [],
            bdyxcdList: [],
            changeStateList: [],
            jgfmList: [], // 景观视线名称列表
            yqbhdyyList: [], // 引起变化的原因 数组
        };
    },
    created() {
        this.GetEnum();
    },
    mounted() {
        this.getJgsxList();
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID", "GetGLXList"]),
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: this.formdata.itemCode,
            });

            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "YQBHYY") {
                    // 引起变化的原因
                    this.bdlxList = v.dataItems;
                }
                if (v.COLUMNID === "YXPG") {
                    // 影响评估
                    this.bdyxcdList = v.dataItems;
                }
                if (v.COLUMNID === "XZSFFSBH") {
                    // 现状是否发生变动
                    this.changeStateList = v.dataItems;
                }
            });
        },
        // 获取景观视线列表
        async getJgsxList() {
            let res = await this.GetGLXList({
                itemcode: this.formdata.itemCode,
                lm: "jcsj",
            });
            this.jgfmList = res.ResultValue || [];
        },
        GetDataNext() {
            this.yqbhdyyList = this.formdata.dataDic.YQBHYY.length
                ? this.formdata.dataDic.YQBHYY.split(",")
                : [];
            // 服务返回为数字类型  选择器需字符串 故需转换
            this.formdata.dataDic.XZSFFSBH = String(
                this.formdata.dataDic.XZSFFSBH
            );
            this.formdata.dataDic.YXPG = String(this.formdata.dataDic.YXPG);
        },
        // 现状是否发生变动
        changeXzsffsbd() {
            if (this.formdata.dataDic.XZSFFSBH == "0") {
                this.yqbhdyyList = [];
                this.formdata.dataDic.YQBHYY = "";
                this.formdata.dataDic.YXPG = "";
            }
        },
        // 引起变化原因 逗号分割的字符串
        changeReason() {
            this.formdata.dataDic.YQBHYY = this.yqbhdyyList.join(",");
        },
    },
};
</script>

<style></style>
